import axios from "axios";
import router from "../../router";

export default {
  state: {
    token: null,
  },
  getters: {
    getToken() {
      let token = localStorage.getItem("token");
      return token;
    },
  },
  mutations: {
    setToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
    },
  },
  actions: {
    createUserSession(state, payload) {
      var params = new URLSearchParams();
      params.append("username", payload.username);
      params.append("password", payload.password);
      axios
        .post(
          "https://uwbestelgemak.nl:8440/connector/html/createUserSession",
          params
        )
        .then((response) => {
          state.commit("setToken", response.data.session);
          state.dispatch("fetchUserPreferences", state, { root: true });
          router.push("/");
        })
        .catch((err) => console.log(err));
    },
    logout(state) {
      state.commit("setToken", null);
      state.commit("setUserPreferences", null);
      router.push("/login");
    },
  },
  modules: {},
};
