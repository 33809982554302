<template>
  <div class="home container">
    <h1>Offertes</h1>
    <table class="table table-striped">
      <thead>
        <th>Id</th>
        <th>Omschrijving</th>
        <th>Datum</th>
        <th>Status</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="quotation in quotations" :key="quotation.id">
          <td>{{ quotation.id }}</td>
          <td>{{ quotation.description }}</td>
          <td>{{ getDate(quotation.job_date) }}</td>
          <td>{{ quotation.quotation_status }}</td>
          <!-- Button -->
          <td align="right">
            <router-link
              v-if="quotation.quotation_number != 0"
              :to="{
                name: 'quotation',
                params: { id: quotation.quotation_number },
              }"
            >
              <button type="button" class="btn btn-primary btn-sm">
                <i class="bi bi-eye"></i>
                &nbsp;View
              </button>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "QuotationsView",
  data() {
    return {
      quotations: [],
    };
  },
  components: {},
  computed: {},
  created() {
    this.fetchQuotations();
  },
  methods: {
    fetchQuotations() {
      axios
        .get("https://dashboard.quantes.nl/getJobsQuotations.php", {
          params: {
            session: this.$store.getters.getToken,
          },
        })
        .then((response) => {
          console.log(response);
          this.quotations = response.data.jobs;
        })
        .catch((err) => console.log(err));
    },
    getDate(datestring) {
      if (datestring) {
        let delivery_date = new Date(datestring);
        let yyyy = delivery_date.getFullYear();
        let mm = delivery_date.getMonth() + 1; // Months start at 0!
        let dd = delivery_date.getDay();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return dd + "-" + mm + "-" + yyyy;
      }
    },
  },
};
</script>
