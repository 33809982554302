import { createRouter, createWebHistory } from "vue-router";
import OrderView from "../views/OrderView.vue";
import InvoiceView from "../views/InvoiceView.vue";
import LoginView from "../views/LoginView.vue";
import ResourceView from "../views/ResourceView.vue";
import QuotationsView from "../views/QuotationsView.vue";
import QuotationView from "../views/QuotationView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: OrderView,
  },
  {
    path: "/invoices",
    name: "invoices",
    component: InvoiceView,
  },
  {
    path: "/quotations",
    name: "quotations",
    component: QuotationsView,
  },
  {
    path: "/quotation/:id",
    name: "quotation",
    component: QuotationView,
  },
  {
    path: "/resource/:id/:table",
    name: "resource",
    component: ResourceView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
