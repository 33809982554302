<template>
  <div class="home container">
    <h1>Facturen</h1>
    <table class="table table-striped">
      <thead>
        <th>Id</th>
        <th>Omschrijving</th>
        <th>Datum</th>
        <th>Status</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="table in getTables" :key="table.id">
          <ResourceRow :id="table.id" :table="1" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ResourceRow from "@/components/ResourceRow.vue";

export default {
  name: "InvoiceView",
  components: {
    ResourceRow,
  },
  computed: {
    getTables() {
      console.log(this.$store.getters.getTables);
      return this.$store.getters.getTables;
    },
  },
  created() {
    this.$store.dispatch("fetchTables", 1);
  },
  methods: {},
};
</script>
