import axios from "axios";
// import router from "../../router";

export default {
  state: {
    tables: [],
    page: 1,
    totalpages: 0,
    records: 25,
    totalrecords: 0,
  },
  getters: {
    getTables(state) {
      return state.tables;
    },
    getPage(state) {
      return state.page;
    },
    getTotalPages(state) {
      return state.totalpages;
    },
    getRecords(state) {
      return state.records;
    },
    getTotalRecords(state) {
      return state.totalrecords;
    },
  },
  mutations: {
    setTables(state, tables) {
      state.tables = tables;
    },
    setPage(state, page) {
      state.page = page;
    },
    setTotalPages(state, totalpages) {
      state.totalpages = totalpages;
    },
    setRecords(state, records) {
      state.records = records;
    },
    setTotalRecords(state, totalrecords) {
      state.totalrecords = totalrecords;
    },
  },
  actions: {
    fetchTables(state, table) {
      state.commit("setTables", []);
      state.commit("setPage", null);
      state.commit("setTotalPages", null);
      state.commit("setRecords", null);
      state.commit("setTotalRecords", null);

      var params = new URLSearchParams();
      params.append("session", state.getters.getToken);
      params.append("table", table);
      params.append("page", state.getters.getPage);
      params.append("records", state.getters.getRecords);
      axios
        .post("https://uwbestelgemak.nl:8440/connector/html/listTables", params)
        .then((response) => {
          console.log(response);
          state.commit("setTables", response.data.data);
          state.commit("setPage", response.data.currentpage);
          state.commit("setTotalPages", response.data.totalpages);
          state.commit("setRecords", response.data.currentrecords);
          state.commit("setTotalRecords", response.data.totalrecords);
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
};
