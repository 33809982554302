<template>
  <div class="home container">
    <h1>
      Offerte
      <small>{{ quotation.quotation_number }}</small>
    </h1>
    <h3>{{ quotation.description }}</h3>
    <table class="table table-striped">
      <tbody>
        <tr>
          <th scope="row">Bedrijf</th>
          <td>{{ quotation.company }}</td>
        </tr>
        <tr>
          <th scope="row">Contact persoon</th>
          <td>{{ quotation.contact_name }}</td>
        </tr>
        <tr>
          <th scope="row">datum</th>
          <td>{{ getDate(quotation.quotation_date) }}</td>
        </tr>
        <tr>
          <th scope="row">Prijs</th>
          <td>€{{ quotation.price }}</td>
        </tr>
      </tbody>
    </table>
    {{ quotation }}
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "QuotationsView",
  data() {
    return {
      quotation_number: this.$route.params.id,
      quotation: {},
    };
  },
  components: {},
  computed: {},
  created() {
    this.fetchQuotation();
  },
  methods: {
    fetchQuotation() {
      axios
        .get("https://dashboard.quantes.nl/handleJobsQuotationsDetails.php", {
          params: {
            session: this.$store.getters.getToken,
            quotation_number: this.quotation_number,
          },
        })
        .then((response) => {
          console.log(response);
          this.quotation = response.data;
        })
        .catch((err) => console.log(err));
    },
    getDate(datestring) {
      if (datestring) {
        let delivery_date = new Date(datestring);
        let yyyy = delivery_date.getFullYear();
        let mm = delivery_date.getMonth() + 1; // Months start at 0!
        let dd = delivery_date.getDay();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return dd + "-" + mm + "-" + yyyy;
      }
    },
  },
};
</script>
