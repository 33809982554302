<template>
  <div class="home container">
    <h1>Orders</h1>
    <table class="table table-striped">
      <thead>
        <th>Id</th>
        <th>Omschrijving</th>
        <th>Datum</th>
        <th>Status</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="order in getOrders" :key="order.id">
          <ResourceRow :id="order.id" :table="2" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ResourceRow from "@/components/ResourceRow.vue";

export default {
  name: "OrderView",
  components: {
    ResourceRow,
  },
  computed: {
    getOrders() {
      return this.$store.getters.getTables;
    },
  },
  created() {
    this.$store.dispatch("fetchTables", 2);
  },
  methods: {},
};
</script>
