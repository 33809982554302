<template lang="html">
  <td>
    <!-- Id -->
    <strong v-if="table == 2">{{ resource.job_number }}</strong>
    <strong v-if="table == 1">{{ resource.quotation_number }}</strong>
    &nbsp;
    <small>{{ this.id }}</small>
  </td>
  <td>{{ resource.description }}</td>
  <!-- Date -->
  <td v-if="table == 2">{{ getDate(resource.delivery_date) }}</td>
  <td v-if="table == 1">{{ getDate(resource.quotation_date) }}</td>
  <!-- Status -->
  <td v-if="table == 2">{{ resource.job_status }}</td>
  <td v-if="table == 1">{{ resource.quotation_status }}</td>
  <!-- Button -->
  <td align="right">
    <router-link
      :to="{ name: 'resource', params: { id: this.id, table: this.table } }"
    >
      <button type="button" class="btn btn-primary btn-sm">
        <i class="bi bi-eye"></i>
        &nbsp;View
      </button>
    </router-link>
  </td>
</template>

<script>
import axios from "axios";

export default {
  name: "ResourceRow",
  props: {
    id: Number,
    table: Number,
  },
  data() {
    return {
      resource: {},
    };
  },
  created() {
    // console.log(this.id);
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      axios
        .get("https://uwbestelgemak.nl:8440/connector/html/detailTables", {
          params: {
            session: this.$store.getters.getToken,
            table: this.table,
            id: this.id,
          },
        })
        .then((response) => {
          console.log(response);
          this.resource = response.data;
        })
        .catch((err) => console.log(err));
    },
    getDate(datestring) {
      if (datestring) {
        let delivery_date = new Date(datestring);
        let yyyy = delivery_date.getFullYear();
        let mm = delivery_date.getMonth() + 1; // Months start at 0!
        let dd = delivery_date.getDay();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return dd + "-" + mm + "-" + yyyy;
      }
    },
  },
};
</script>

<style lang="css" scoped></style>
