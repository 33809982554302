import axios from "axios";

export default {
  state: {
    userPreferences: null,
  },
  getters: {
    getUserPreferences(state) {
      let userPreferences = null;
      if (state.userPreferences) {
        userPreferences = state.userPreferences;
      } else {
        userPreferences = localStorage.getItem("userPreferences");
      }
      return userPreferences;
    },
  },
  mutations: {
    setUserPreferences(state, userPreferences) {
      state.userPreferences = userPreferences;
      localStorage.setItem("userPreferences", userPreferences);
    },
  },
  actions: {
    fetchUserPreferences(state) {
      axios
        .get(
          "https://uwbestelgemak.nl:8440/connector/html/getUserPreferences",
          {
            params: {
              session: state.getters.getToken,
            },
          }
        )
        .then((response) => {
          // console.log(response.data.user);
          state.commit(
            "setUserPreferences",
            JSON.stringify(response.data.user)
          );
        })
        .catch((err) => console.log(err));
    },
  },
  modules: {},
};
