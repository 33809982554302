import { createStore } from "vuex";
import auth from "./modules/auth.js";
import user from "./modules/user.js";
import tables from "./modules/tables.js";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    tables,
  },
});
