<template lang="html">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#sj' + sub_job.id"
        aria-expanded="true"
        :aria-controls="'sj' + sub_job.id"
      >
        {{ sub_job.description }} - €{{ sub_job.job_price }}
      </button>
    </h2>
    <div
      :id="'sj' + sub_job.id"
      class="accordion-collapse collapse"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div class="accordion-body">
        <!-- {{ sub_job }} -->
        <table class="table table-striped table-hover">
          <tbody>
            <tr>
              <td>
                <strong>Id</strong>
              </td>
              <td>{{ sub_job.id }}</td>
            </tr>
            <tr>
              <td>
                <strong>Omschrijving</strong>
              </td>
              <td>{{ sub_job.description }}</td>
            </tr>
            <tr>
              <td>
                <strong>Oplage</strong>
              </td>
              <td>{{ sub_job.run_01 }}</td>
            </tr>
            <tr>
              <td>
                <strong>Prijs</strong>
              </td>
              <td>€{{ sub_job.job_price }}</td>
            </tr>
          </tbody>
        </table>
        <h5>Checklist</h5>
        <table class="table table-striped table-hover">
          <tbody>
            <tr v-for="cl_item in sub_job.checklist" v-bind:key="cl_item">
              <td>
                <strong>{{ cl_item.name }}</strong>
              </td>
              <td>{{ cl_item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubJob",
  props: {
    sub_job: Object,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="css" scoped></style>
