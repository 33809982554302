<template>
  <div class="home container">
    <h1>Detail overzicht</h1>
    <ResourceDetail :id="id" :table="table" />
  </div>
</template>

<script>
import ResourceDetail from "@/components/ResourceDetail.vue";

export default {
  name: "ResourceView",
  created() {
    // console.log(this.id);
  },
  components: {
    ResourceDetail,
  },
  data() {
    return {
      id: this.$route.params.id,
      table: this.$route.params.table,
    };
  },
  computed: {
    getTables() {
      console.log(this.$store.getters.getTables);
      return this.$store.getters.getTables;
    },
  },
  methods: {},
};
</script>
