<template lang="html">
  <div class="">
    <table class="table table-striped table-borderless">
      <tbody>
        <tr>
          <td width="250"><strong>Ordernummer</strong></td>
          <td>{{ resource.job_number }}</td>
        </tr>
        <tr>
          <td><strong>Omschrijving</strong></td>
          <td>{{ resource.description }}</td>
        </tr>
        <tr>
          <td><strong>Project manager</strong></td>
          <td>{{ resource.creator }}</td>
        </tr>
        <tr>
          <td><strong>Contactpersoon</strong></td>
          <td>{{ resource.contact_name }}</td>
        </tr>
        <tr>
          <td><strong>Orderdatum</strong></td>
          <td>{{ getDate(resource.job_date) }}</td>
        </tr>
        <tr>
          <td><strong>Leverdatum</strong></td>
          <td>{{ getDate(resource.delivery_date) }}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <table class="table table-borderless">
      <tbody>
        <tr>
          <td width="250"><strong>Status</strong></td>
          <td>
            <strong class="text-primary">{{ resource.job_status }}</strong>
          </td>
        </tr>
      </tbody>
    </table>
    <hr />
    <h3>Sub orders</h3>
    <div class="accordion" id="accordionExample">
      <SubJob
        v-for="sub_job in resource.sub_jobs"
        v-bind:key="sub_job.id"
        :sub_job="sub_job"
      />
    </div>
    <hr />
    <div v-if="table == 2">
      <h3>Bestanden</h3>
      <table class="table table-striped">
        <tbody>
          <tr>
            <th><strong>Naam</strong></th>
            <th><strong>Pagina's</strong></th>
            <th><strong>Grootte</strong></th>
          </tr>
          <tr v-for="file in contentData" v-bind:key="file.filename">
            <td>
              <a href="#" @click="fetchContentDataFile(file.filename)">
                {{ file.filename }}
              </a>
            </td>
            <td>{{ file.pages }}</td>
            <td>{{ bytesToSize(file.size) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SubJob from "@/components/SubJob";

export default {
  name: "ResourceDetail",
  props: {
    id: String,
    table: String,
  },
  components: {
    SubJob,
  },
  data() {
    return {
      resource: {},
      contentData: {},
    };
  },
  created() {
    // console.log(this.id);
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      axios
        .get("https://uwbestelgemak.nl:8440/connector/html/detailTables", {
          params: {
            session: this.$store.getters.getToken,
            table: this.table,
            id: this.id,
          },
        })
        .then((response) => {
          // console.log(response);
          this.resource = response.data;
          if (this.table == 2) {
            this.fetchContentData();
          }
        })
        .catch((err) => console.log(err));
    },
    fetchContentData() {
      axios
        .get("https://dashboard.quantes.nl/handleContentData.php", {
          params: {
            session: this.$store.getters.getToken,
            job_number: this.resource.job_number,
          },
        })
        .then((response) => {
          console.log(response);
          this.contentData = response.data;
        })
        .catch((err) => console.log(err));
    },
    fetchContentDataFile(filename) {
      axios
        .get("https://dashboard.quantes.nl/handleContentData.php", {
          params: {
            session: this.$store.getters.getToken,
            job_number: this.resource.job_number,
            filename: filename,
          },
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);

          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => console.log(err));
    },
    getDate(datestring) {
      if (datestring) {
        let delivery_date = new Date(datestring);
        let yyyy = delivery_date.getFullYear();
        let mm = delivery_date.getMonth() + 1; // Months start at 0!
        let dd = delivery_date.getDay();

        if (dd < 10) dd = "0" + dd;
        if (mm < 10) mm = "0" + mm;

        return dd + "-" + mm + "-" + yyyy;
      }
    },
    bytesToSize(bytes) {
      let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
  },
};
</script>

<style lang="css" scoped></style>
